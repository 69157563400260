import React, { Component } from 'react';
import moonBunny from '../assets/Floating Bunny.mp4';

class FAQ extends Component {


  render() {
    return (

      <div id="faq" class="faqBg">

        <div class="accordin reveal">

          <div class="conT2">FREQUENTLY ASKED QUESTIONS</div>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>What inspired our project?</summary>
            <div class="faq__content">
              <p>United by our passion for art, we envisioned a digital art collection that would captivate
                hearts and spread positivity.</p>
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>What are the additional perks of owning a DBC NFT?</summary>
            <div class="faq__content">
              <p>DBC holders will get a lifetime discount of 15% to our clothing line. We are creating an
                athleisure line that elevates your active lifestyle. We will blend sophistication, comfort
                and fashion forward style into every stitch. Our commitment to quality is unwavering.</p>
            </div>
          </details>
          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>Which blockchain is the NFT programmed onto?</summary>
            <div class="faq__content">
              <p>Ethereum. Why did we choose this? Ethereum is extremely secure and has a complex
                data architecture.</p>
            </div>
          </details>

          <details data-aos="fade-up" data-aos-duration="1000">
            <summary>What is the minting price?</summary>
            <div class="faq__content">
              <p>$333</p>
            </div>
          </details>
       
        </div>

         <video class="faqR" src={moonBunny} autoPlay loop playsInline muted />

      </div>


    )
  }
}

export default FAQ;

