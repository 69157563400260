import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import astronaut from '../assets/astronaut.png'
import road from '../assets/road.png'

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                <div class="about">
                    <div class="conT">Diamond <br /> Bunny</div>
                    <div class="storyCon">
                        <p>Welcome to Diamond Bunny Club, where art, blockchain and limitless imagination
                            intertwine to create an extraordinary digital wonder! DBC is an exclusive collection of
                            10,000 NFTs programmed onto the Ethereum blockchain.</p>
                        <p>Prepare to embark on a mesmerizing adventure where bunnies dress up in an
                            assortment of clothes and costumes and gather in outer space to explore the galaxy.
                            Our brand comes with an exciting clothing line, release of a unique meme coin and
                            donations to animal charities. As passionate advocates for animal welfare, DBC holds a
                            deep commitment to giving back.</p>

                        <p>Join us as we unlock the door to a galaxy where rarity sparkles like a diamond!
                        </p>
                        <button class="os"> <div class="glitch-wrapper">
                            <div class="glitch" data-text="OPENSEA">OPENSEA</div>
                        </div></button>
                    </div>
                </div>

                <img class="astronaut" src={astronaut} />
                <img class="road" src={road} />



            </div>
        )
    }
}

export default Story;

