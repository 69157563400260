import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import layer1 from '../assets/Bunny-roadmap.jpg';
import layer2 from '../assets/layer2.png';
import layer3 from '../assets/layer3.png';
import layer4 from '../assets/layer4.png';
import layer5 from '../assets/layer5.png';
import layer6 from '../assets/layer6.png';
import layer7 from '../assets/layer7.png';
import layer2Box from '../assets/layer1-box.png';
import layer3Box from '../assets/layer3-box.png';
import layer4Box from '../assets/layer4-box.png';
import layer5Box from '../assets/layer5-box.png';
import layer6Box from '../assets/layer6-box.png';
import layer7Box from '../assets/layer7-box.png';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import discord from '../assets/discord.png';
import insta from '../assets/instagram.png';
import Ticker from '../components/ticker';
import layer1M from '../assets/layer1M.png'
import layer2M from '../assets/layer2M.png'
import layer3M from '../assets/layer3M.png'
import layer4M from '../assets/layer4M.png'
import layer5M from '../assets/layer5M.png'
import layer7M from '../assets/layer7M.png'
import rabbit from '../assets/rabbitFlag.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const discrd = () => {
    window.open("#");
}

const tweet = () => {
    //window.open("https://twitter.com/DiamondBunnyNFT");
    window.open("#");
}

const instagram = () => {
    //window.open("https://www.instagram.com/diamondbunnyclub/?hl=en");
    window.open("#");
}

const opensea = () => {
    window.open("#");
}


class Roadmap extends Component {

    render() {
        return (

            <div class="boxWrapRm">
                <div class="rmMain">


                    <img class="rabbitWithFlag" src={rabbit} />
                    <img class="layer7" src={layer7} />
                    <img class="layer7Box" src={layer7Box} />

                    <img class="layer6" src={layer6} />
                    <img class="layer6Box" src={layer6Box} />

                    <img class="layer5" src={layer5} />
                    <img class="layer5Box" src={layer5Box} />

                    <img class="layer4" src={layer4} />
                    <img class="layer4Box" src={layer4Box} />

                    <img class="layer3" src={layer3} />
                    <img class="layer3Box" src={layer3Box} />

                    <img class="layer2" src={layer2} />
                    <img class="layer2Box" src={layer2Box} />

                    <img class="layer1" src={layer1} />
                    <div class="boxWrap2Footer">
                        <Ticker />

                        <div class="footer1">

                            <div class="socialIcons">
                                <img onClick={opensea} src={os} />
                                <img onClick={discrd} src={discord} />
                                <img onClick={tweet} src={twitter} />
                                <img onClick={instagram} src={insta} />
                            </div>
                            <div class="line2"></div>

                            <div class="footer">
                                <div class="copyright">@2023 DBC. All rights reserved </div>
                                <div class="terms">
                                    <div>Privacy Policy</div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <div class="rmMobile">
                    <img src={layer1M} />
                    <img src={layer2M} />
                    <img src={layer3M} />
                    <img src={layer4M} />
                    <img src={layer5M} />
                    <img src={layer7M} />
                </div>

                <div class="boxWrap2FooterM">
                    <Ticker />

                    <div class="footer1">

                        <div class="socialIcons">
                            <img onClick={opensea} src={os} />
                            <img onClick={discrd} src={discord} />
                            <img onClick={tweet} src={twitter} />
                            <img onClick={instagram} src={insta} />
                        </div>
                        <div class="line2"></div>

                        <div class="footer">
                            <div class="copyright">@2023 DBC. All rights reserved </div>
                            <div class="terms">
                                <div>Privacy Policy</div>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        )
    }
}

export default Roadmap;

