import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import alienship from '../assets/alienship.png'
import road from '../assets/road.png'

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Shop extends Component {

    render() {
        return (

            <div class="boxWrap2Shop">
                <img class="alienship" src={alienship} />

                <div class="aboutShop">
                    <div class="name">
                        <p class="shopP">Reach for the Stars of Digital Ownership!</p>
                    </div>
                    <button class="os"> <div class="glitch-wrapper">
                        <div class="glitch" data-text="SHOP NOW">SHOP NOW</div>
                    </div></button>
                </div>

            </div>
        )
    }
}

export default Shop;

