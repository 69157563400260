import './App.css';
import './sky.css';
import React, { Component } from 'react';
import Story from "./components/story";
import Ticker from './components/ticker';
import Ticker2 from './components/ticker2';
import Team from './components/team';
import FAQ from './components/faq';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import moonBunny from './assets/Moon Bunny.mp4';
import rocket from './assets/rocket.png';
import rabbit from './assets/rabbitInside.png';
import fire from './assets/fire.png';
import $ from "jquery";
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import back from './assets/back.png';
import usd from './assets/dollar.png';
import eth from './assets/ethereum.png';
//import window from './assets/window.png';
import Carousel from './components/carousel';
import Roadmap from './components/roadmap';
import Shop from './components/shop';
import mintPic from './assets/t1.png';
import mintbg from './assets/mint.png';
import './star.css';
import Ticker3 from './components/ticker3';
import CustomForm from './components/customForm';
import moonBunny2 from './assets/Plane Crash.mp4';
import os from './assets/os.png';
import twitter from './assets/twitter.png';
import discord from './assets/discord.png';
import insta from './assets/instagram.png';

var Scroll = require('react-scroll');
var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});

$(function () {
	const leftPlanet = $('.cont');
	const rightPlanet = $('.cont');

	const rocketMainDiv = $('.rocketMainDiv');

	$(window).on('scroll', function () {
		const containerTop = leftPlanet.offset().top;
		const containerBottom = containerTop + leftPlanet.outerHeight();
		const windowTop = $(window).scrollTop();
		const windowBottom = windowTop + $(window).height();


		if (windowTop > containerTop) {
			rocketMainDiv.addClass('rocketMainDivAnimation');
			//rightPlanet.addClass('animatePlanetR')
		} else {
			rocketMainDiv.removeClass('rocketMainDivAnimation');
			//rightPlanet.removeClass('animatePlanetR');

		}


		console.log("containerTop :" + containerTop);
		console.log("containerBottom :" + containerBottom);
		console.log("windowTop :" + windowTop);
		console.log("windowBottom :" + windowBottom);


	});
});


const discrd = () => {
    window.open("#");
}

const tweet = () => {
    //window.open("https://twitter.com/DiamondBunnyNFT");
    window.open("#");
}

const instagram = () => {
    //window.open("https://www.instagram.com/diamondbunnyclub/?hl=en");
    window.open("#");
}

const opensea = () => {
    window.open("#");
}


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let totalSupplyNFT2;
let maxMintNFTs;
let onlyLeft;
let owner;
let publicSale;
let user_input;
let wMintAmount;
let myTokens = [];
let myTokens2 = [];
let adminWhitelistAddresses = [];
let adminPanel = 0;
let adminWhitelistAddresses2 = [];
let maxTokensToBuy = "";
let testingvalue;
let wlMint;
let FinalResult;
let wlMsg = "Whitelist Mint";
let public_sale_cost;
let presalesale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let usdtAmount = 25000000;
let token_contract;
let publicSaleCostETH;

const ABI = [
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mintETH",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_decimals",
				"type": "uint256"
			}
		],
		"name": "setDecimals",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCostETH",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCostETH",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_tokenContract",
				"type": "address"
			}
		],
		"name": "setTokenContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "MAX_SUPPLY",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCostETH",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "token_Contract",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const address = "0x42fbC6502db20842c4f46B823bB18B813d18F965";

const token_ABI = [
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burn",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "burnFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "subtractedValue",
				"type": "uint256"
			}
		],
		"name": "decreaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "addedValue",
				"type": "uint256"
			}
		],
		"name": "increaseAllowance",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "pause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Paused",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "amount",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "unpause",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": false,
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "Unpaused",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "account",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "paused",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const token_address = "0xC8d9b4FfFF2109bc7376420Ed23C02973696A25D";

let contract;

class App extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		currentPrice: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		statusError: false,
		statusLoading: false,
		success: false,
		nftMintingAmount: 1,
		totalValue: "",
		presaleValue: "0",
		maxmint: '',
		_adminPanel: 0,
		_adminWhitelistAddresses: [],
		_adminWhitelistAddresses2: [],
		_maxTokensToBuy: "",
		_testingValue: '',
		_wlMint: '',
		_FinalResult: '',
		_wlMsg: 'Whitelist Mint',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		gallery: '',
		_navbarOpen: 0,
		_approved: '',
		_ethMint: 0,
		_usdMint: 0,
		_showMintBox: 0,
		_publicSaleCostETH: ''
	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	async componentDidMount() {

		try {

			if (localStorage?.getItem('src') == "1") {
				localStorage.setItem('src', "2");

				this.setState({ gallery: "1" });

			} else

				if (localStorage?.getItem('src') == "2") {
					localStorage.setItem('src', "3");

					this.setState({ gallery: "2" });

				} else

					if (localStorage?.getItem('src') == "3") {
						localStorage.setItem('src', "4");

						this.setState({ gallery: "3" });

					} else

						if (localStorage?.getItem('src') == "4") {
							localStorage.setItem('src', "1");

							this.setState({ gallery: "4" });

						} else {

							localStorage.setItem('src', "1");
							this.setState({ gallery: "1" });

						}


		} catch (err) {
			console.log(err);
		}

		if (localStorage?.getItem('isWalletConnected') === 'true') {
			try {
				const providerOptions = {
					walletconnect: {
						package: WalletConnectProvider, // required
						options: {
							infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
						}
					},
					/*coinbasewallet: {
						package: CoinbaseWalletSDK, // Required
						options: {
							appName: "Aterium Universe", // Required
							infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
							rpc: "", // Optional if `infuraId` is provided; otherwise it's required
							chainId: 1, // Optional. It defaults to 1 if not provided
							darkMode: true // Optional. Use dark theme, defaults to false
						}
					}*/
				};

				const web3Modal = new Web3Modal({
					network: "mainnet", // optional
					cacheProvider: true, // optional
					providerOptions // required
				});



				const provider = await web3Modal.connect();

				//  Enable session (triggers QR Code modal)
				await provider.enable();

				const web3 = new Web3(provider);
				console.log("provider : " + provider);
				// Subscribe to accounts change
				provider.on("accountsChanged", (accounts) => {
					console.log(accounts);
				});

				// Subscribe to chainId change
				provider.on("chainChanged", (chainId) => {
					console.log(chainId);
				});

				// Subscribe to provider connection
				provider.on("connect", (info) => {
					console.log(info);
					console.log("I'm LOGGED IN");
				});

				// Subscribe to provider disconnection
				provider.on("disconnect", (error) => {
					console.log(error);
				});

				// test if wallet is connected
				if (web3Modal.cachedProvider) {
					// connected now you can get accounts
					console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
					console.log("provider :" + provider);

					const accounts = await web3.eth.getAccounts();

					account = accounts[0];
					this.setState({ walletAddress: account });

					//const signer = (new Web3Provider(window.ethereum).getSigner)();

					contract = new web3.eth.Contract(ABI, address); console.log("contract :" + contract);
					token_contract = new web3.eth.Contract(token_ABI, token_address);

					if (provider) {


						//	(async () => {

						if (web3Modal.cachedProvider != "walletconnect" /*&& web3Modal.cachedProvider != "coinbasewallet"*/) {

							const chainId = 5;

							if (window.ethereum.networkVersion !== chainId) {
								try {
									await window.ethereum.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],

													/*chainName: 'Rinkeby Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'RinkebyETH', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://rinkeby.infura.io/v3/'],*/
													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/

													/*chainName: 'Ethereum Mainnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://etherscan.io'],*/
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								let allowance = await token_contract.methods.allowance(account, address).call();

								if (allowance >= usdtAmount) {
									this.setState({ _approved: true });
									console.log("true Allowance:" + allowance);


								} else {
									this.setState({ _approved: false });
									console.log("false Allowance:" + allowance);

								}

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								publicSaleCostETH = await contract.methods.publicSaleCostETH().call();
								this.setState({ _publicSaleCostETH: publicSaleCostETH });
								console.log("publicSaleCostETH :" + publicSaleCostETH);

								public_mint_status = await contract.methods.public_mint_status().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);

								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//..........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 10000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 10000) {

										onlyLeft = 10000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///................................///



								//..........................................................................//

							}
							catch (err) {
								console.log("err: " + err);

							}

						} else if (web3Modal.cachedProvider == "walletconnect") {

							const chainId = 5;

							if (WalletConnectProvider.networkVersion !== chainId) {
								try {
									await WalletConnectProvider.request({
										method: 'wallet_switchEthereumChain',
										params: [{ chainId: web3.utils.toHex(chainId) }],
									});
								} catch (err) {
									// This error code indicates that the chain has not been added to MetaMask.
									if (err.code === 4902) {
										await window.ethereum.request({
											method: 'wallet_addEthereumChain',
											params: [
												{
													/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

													chainName: 'Goerli Test Network',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
													rpcUrls: ['https://goerli.infura.io/v3/'],

													/*chainName: 'Mumbai Testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
													rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],*/
												},
											],
										});
									}
								}
							}


							try {

								try {
									localStorage.setItem('isWalletConnected', true);
								} catch (ex) {
									console.log(ex)
								}

								let allowance = await token_contract.methods.allowance(account, address).call();

								if (allowance >= usdtAmount) {
									this.setState({ _approved: true });
									console.log("true Allowance:" + allowance);


								} else {
									this.setState({ _approved: false });
									console.log("false Allowance:" + allowance);

								}

								totalSupplyNFT = await contract.methods.totalSupply().call();
								this.setState({ totalSupply: totalSupplyNFT });
								console.log("Total Supply:" + totalSupplyNFT);

								publicSale = await contract.methods.balanceOf(account).call();
								this.setState({ myNFTWallet: publicSale });

								public_sale_cost = await contract.methods.publicSaleCost().call();
								this.setState({ _public_sale_cost: public_sale_cost });
								console.log("public_sale_cost :" + public_sale_cost);
								this.setState({ _cost: public_sale_cost });
								this.setState({ _mintStatus: false });

								MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
								this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
								console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

								publicSaleCostETH = await contract.methods.publicSaleCostETH().call();
								this.setState({ _publicSaleCostETH: publicSaleCostETH });
								console.log("publicSaleCostETH :" + publicSaleCostETH);

								public_mint_status = await contract.methods.public_mint_status().call();
								this.setState({ _public_mint_status: public_mint_status });
								console.log("public_mint_status :" + public_mint_status);

								owner = await contract.methods.owner().call();
								console.log("Owner" + owner);
								//..........................................................................//
								if (owner == account) {
									console.log("owner : " + owner)
									onlyLeft = 10000 - totalSupplyNFT;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * 0;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								} else {
									mintAmount = 1;

									if (totalSupplyNFT == 10000) {

										onlyLeft = 10000 - totalSupplyNFT;
										mintAmount = onlyLeft;
										this.setState({ msg: "SOLD OUT" });

									} else {
										mintAmount = 1;
										onlyLeft = MAX_PER_WALLET - publicSale;

										if (mintAmount > onlyLeft) {
											mintAmount = onlyLeft;
										}
										//mintAmount = onlyLeft;

										valueOfNFTs = mintAmount * this.state._cost;
										wMintAmount = mintAmount;


										this.setState({ nftMintingAmount: mintAmount });

										this.setState({ totalValue: valueOfNFTs });
									}
								}
								///................................///



								//..........................................................................//

							} catch (err) {
								console.log("err: " + err);

							}


						}
						//})();

						//.....................................................................//

						// Legacy providers may only have ethereum.sendAsync
						const chainId = await provider.request({
							method: 'eth_chainId'
						})

					} else {

						// if the provider is not detected, detectEthereumProvider resolves to null
						console.error('Please install a Valid Wallet');
						alert('A valid provider could not be found!');

					}

				}
			} catch (ex) {
				console.log(ex);
			}
		} else {

			console.log("NOT CONNECTED");

		}

	}

	onSubmitMinus = async event => {
		event.preventDefault();

		mintAmount = mintAmount - 1;

		if (mintAmount < 1) {
			mintAmount = 1
		}

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 10000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {
			if (totalSupplyNFT < 10000) {

				onlyLeft = MAX_PER_WALLET - publicSale;

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;


				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting - :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmitPlus = async event => {
		event.preventDefault();

		//,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
		mintAmount = mintAmount + 1;

		if (owner == account) {
			console.log("owner : " + owner)
			onlyLeft = 1000 - totalSupplyNFT;

			if (mintAmount > onlyLeft) {
				mintAmount = onlyLeft;
			}

			valueOfNFTs = mintAmount * 0;



			this.setState({ nftMintingAmount: mintAmount });

			this.setState({ totalValue: valueOfNFTs });
		} else {

			if (totalSupplyNFT < 10000) {

				onlyLeft = MAX_PER_WALLET - publicSale;
				console.log(onlyLeft);

				if (mintAmount > onlyLeft) {
					mintAmount = onlyLeft;
				}
				valueOfNFTs = mintAmount * this.state._cost;
				wMintAmount = mintAmount;

				this.setState({ nftMintingAmount: mintAmount });
				console.log("nftMintingAmount - hitting + :" + this.state.nftMintingAmount);

				this.setState({ totalValue: valueOfNFTs });
			}
		}

	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account });

					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account });


					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}

			}
		} catch (err) {

			console.log(err);

		}
	}

	ethMint = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mintETH(mintAmount * 1).send({ gasLimit: 385000, from: account, value: mintAmount * publicSaleCostETH });

					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mintETH(mintAmount * 1).send({ gasLimit: 385000, from: account, value: 0 });


					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);
					await new Promise(resolve => setTimeout(resolve, 5000));
					window.location.reload(true);


				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}

			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});

		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("provider :" + web3Modal.cachedProvider);
			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });
			//const signer = (new Web3Provider(window.ethereum).getSigner)();

			contract = new web3.eth.Contract(ABI, address);
			token_contract = new web3.eth.Contract(token_ABI, token_address);
			console.log("contract :" + contract);
			//const provider = new Provider('https://testnet.era.zksync.dev');
			// Note that we still need to get the Metamask signer
			/*const signer = (new Web3Provider(window.ethereum).getSigner)();
			contractZksync = new web3.eth.Contract(
				ABI,
				address,
				signer
			);
			this.setState({ _provider:provider, _signer:signer, _contractZksync:contractZksync });
			console.log("provider : " + provider);
			console.log("signer : " + signer);
			console.log("contractZksync : " + contractZksync);*/

			if (provider) {


				(async () => {


					if (web3Modal.cachedProvider != "walletconnect") {

						const chainId = 5;

						if (window.ethereum.networkVersion !== chainId) {
							try {
								await window.ethereum.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
											},
										],
									});
								}
							}
						}





						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							let allowance = await token_contract.methods.allowance(account, address).call();

							if (allowance >= usdtAmount) {
								this.setState({ _approved: true });
								console.log("true Allowance:" + allowance);


							} else {
								this.setState({ _approved: false });
								console.log("false Allowance:" + allowance);

							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							publicSaleCostETH = await contract.methods.publicSaleCostETH().call();
							this.setState({ _publicSaleCostETH: publicSaleCostETH });
							console.log("publicSaleCostETH :" + publicSaleCostETH);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);

							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);
							//..........................................................................//
							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;
								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 10000 - totalSupplyNFT;
									mintAmount = onlyLeft;
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									//mintAmount = onlyLeft;

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					} else {

						const chainId = 5;

						if (WalletConnectProvider.networkVersion !== chainId) {
							try {
								await WalletConnectProvider.request({
									method: 'wallet_switchEthereumChain',
									params: [{ chainId: web3.utils.toHex(chainId) }],
								});
							} catch (err) {
								// This error code indicates that the chain has not been added to MetaMask.
								if (err.code === 4902) {
									await window.ethereum.request({
										method: 'wallet_addEthereumChain',
										params: [
											{
												/*chainName: 'Smart ChainChain',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart ChainChain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://bsc-dataseed.binance.org/'],*/

												/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
												rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

												/*chainName: 'zkSync alpha testnet',
													chainId: web3.utils.toHex(chainId),
													nativeCurrency: { name: 'zkSync alpha testnet', decimals: 18, symbol: 'ETH' },
													rpcUrls: ['https://zksync2-testnet.zkscan.io/'],*/

												/*chainName: 'Ethereum Mainnet',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Ethereum', decimals: 18, symbol: 'ETH' },
												rpcUrls: ['https://etherscan.io'],*/

												chainName: 'Goerli Test Network',
												chainId: web3.utils.toHex(chainId),
												nativeCurrency: { name: 'Goerli Test Network', decimals: 18, symbol: 'GoerliETH' },
												rpcUrls: ['https://goerli.infura.io/v3/'],
											},
										],
									});
								}
							}
						}




						try {

							try {
								localStorage.setItem('isWalletConnected', true);
							} catch (ex) {
								console.log(ex)
							}

							let allowance = await token_contract.methods.allowance(account, address).call();

							if (allowance >= usdtAmount) {
								this.setState({ _approved: true });
								console.log("true Allowance:" + allowance);


							} else {
								this.setState({ _approved: false });
								console.log("false Allowance:" + allowance);

							}

							totalSupplyNFT = await contract.methods.totalSupply().call();
							this.setState({ totalSupply: totalSupplyNFT });
							console.log("Total Supply:" + totalSupplyNFT);

							publicSale = await contract.methods.balanceOf(account).call();
							this.setState({ myNFTWallet: publicSale });

							public_sale_cost = await contract.methods.publicSaleCost().call();
							this.setState({ _public_sale_cost: public_sale_cost });
							console.log("public_sale_cost :" + public_sale_cost);
							this.setState({ _cost: public_sale_cost });
							this.setState({ _mintStatus: false });

							MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
							this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
							console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

							publicSaleCostETH = await contract.methods.publicSaleCostETH().call();
							this.setState({ _publicSaleCostETH: publicSaleCostETH });
							console.log("publicSaleCostETH :" + publicSaleCostETH);

							public_mint_status = await contract.methods.public_mint_status().call();
							this.setState({ _public_mint_status: public_mint_status });
							console.log("public_mint_status :" + public_mint_status);



							owner = await contract.methods.owner().call();
							console.log("Owner" + owner);

							if (owner == account) {
								console.log("owner : " + owner)
								onlyLeft = 10000 - totalSupplyNFT;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}

								valueOfNFTs = mintAmount * 0;

								wMintAmount = mintAmount;


								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							} else {
								mintAmount = 1;

								if (totalSupplyNFT == 10000) {

									onlyLeft = 0;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}
									this.setState({ msg: "SOLD OUT" });

								} else {
									mintAmount = 1;
									onlyLeft = MAX_PER_WALLET - publicSale;

									if (mintAmount > onlyLeft) {
										mintAmount = onlyLeft;
									}

									valueOfNFTs = mintAmount * this.state._cost;
									wMintAmount = mintAmount;


									this.setState({ nftMintingAmount: mintAmount });

									this.setState({ totalValue: valueOfNFTs });
								}
							}
							///................................///



							//..........................................................................//

						} catch (err) {
							console.log("err: " + err);

						}
					}
				})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	approve = async event => {
		event.preventDefault();

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);
					console.log("usdtAmount:" + usdtAmount);

					let allowance = await token_contract.methods.allowance(account, address).call();

					if (allowance > 0) {

						await token_contract.methods.increaseAllowance(address, usdtAmount).send({ /*maxPriorityFeePerGas: 400000000000,*/ /*gasPrice: 200000000000,*/ gasLimit: 685000, from: account });
						this.setState({ _approved: true });
					} else {

						await token_contract.methods.approve(address, usdtAmount).send({ /*maxPriorityFeePerGas: 400000000000,*/ /*gasPrice: 200000000000,*/ gasLimit: 685000, from: account });
						this.setState({ _approved: true });
					}



				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);

				}

			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + usdtAmount);

					let allowance = await token_contract.methods.allowance(account, address).call();

					if (allowance > 0) {

						await token_contract.methods.increaseAllowance(address, usdtAmount).send({ /*maxPriorityFeePerGas: 400000000000,*/ gasLimit: 685000, from: account });
						this.setState({ _approved: true });
					} else {

						await token_contract.methods.approve(address, usdtAmount).send({/* maxPriorityFeePerGas: 400000000000,*/ gasLimit: 685000, from: account });
						this.setState({ _approved: true });
					}



				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}


	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload(true);

		try {
			localStorage.setItem('isWalletConnected', false)
		} catch (ex) {
			console.log(ex)
		}

	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	usdSelect = async event => {
		event.preventDefault();

		this.setState({ _usdMint: 1 });
		this.setState({ _ethMint: 0 });
		this.setState({ _showMintBox: 1 });
	}

	ethSelect = async event => {
		event.preventDefault();

		this.setState({ _ethMint: 1 });
		this.setState({ _usdMint: 0 });
		this.setState({ _showMintBox: 1 });
	}

	backMintBox = async event => {
		event.preventDefault();

		this.setState({ _showMintBox: 0 });
	}


	render() {

		return (

			<div class="allWrap">

				<div class="light">

					<div class="page-loader">
						<video class="rabbitVid1" src={moonBunny2} autoPlay loop playsInline muted />

					</div>

					<div class="cont">
						{this.state._navbarOpen < 1 ?
							(<div class="miniBarMain">
								<div class="navbarMain"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
							</div>) :
							(<div class="miniBarMain">

								<div class="navbarMain"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
							</div>)}
						<Link to="gallery" spy={true} smooth={true} duration={550}><div><video class="logoM" src="https://cdn.discordapp.com/attachments/1050552088779825176/1117758873491742780/My_Video2.mp4" autoPlay loop playsInline muted /></div></Link>
						<div id="stars"></div>
						<div id="stars2"></div>
						<div id="stars3"></div>
						<div class="meteor-1"></div>
						<div class="meteor-2"></div>
						<div class="meteor-3"></div>
						<div class="meteor-4"></div>
						<div class="meteor-5"></div>
						<div class="meteor-6"></div>
						<div class="meteor-7"></div>
						<div class="meteor-8"></div>
						<div class="meteor-9"></div>
						<div class="meteor-10"></div>
						<div class="meteor-11"></div>
						<div class="meteor-12"></div>
						<div class="meteor-13"></div>
						<div class="meteor-14"></div>
						<div class="meteor-15"></div>

						<Link to="gallery" spy={true} smooth={true} duration={550}>
							<div class="headers">

								<div class="h1">

									<div class="logoDiv"><video class="logo" src="https://cdn.discordapp.com/attachments/1050552088779825176/1117758873491742780/My_Video2.mp4" autoPlay loop playsInline muted /></div>

									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="about" spy={true} smooth={true} duration={550}>About</Link></div>
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>Mint</Link></div>
										<div ><Link activeClass="" id="fontSize" to="team" spy={true} smooth={true} duration={550}>Team</Link></div>
										<div ><Link activeClass="" id="fontSize" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
										<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>Roadmap</Link></div>
									</div>

									<div class="right">
										<div class="socialIcons2">
											<img onClick={opensea} src={os} />
											<img onClick={discrd} src={discord} />
											<img onClick={tweet} src={twitter} />
											<img onClick={instagram} src={insta} />
										</div>
										{this.state.walletAddress === '' ?
											(<form class="connect2">
												<button onClick={this.walletConnect} class="connectBtn" >CONNECT</button>
											</form>) : (<form class="connect2" onClick={this.walletDisconnect}><button class="connectBtn" >
												{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}</button></form>)}

									</div>

								</div>
							</div>

						</Link>
						{this.state._navbarOpen > 0 ?
							(<div class="littleNav" data-aos="fade-left">
								<div ><Link activeClass="" id="fontSize2" to="about" spy={true} smooth={true} duration={550}>About</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550}>Mint</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="team" spy={true} smooth={true} duration={550}>Team</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="faq" spy={true} smooth={true} duration={550}>FAQ</Link></div>
								<div ><Link activeClass="" id="fontSize2" to="rm" spy={true} smooth={true} duration={550}>Roadmap</Link></div>
								<div class="socialIcons2">
									<img onClick={opensea} src={os} />
									<img onClick={discrd} src={discord} />
									<img onClick={tweet} src={twitter} />
									<img onClick={instagram} src={insta} />
								</div>
							</div>) : null}
						<div class="imgs3">

							<div class="title">Diamond Bunny Club</div>

						</div>
						<div class="rocketMainDiv">

							<img class="rocket" src={rocket} />
							<img class="rabbit" src={rabbit} />
							<div class="fireMain">
								<img class="fire1" src={fire} />
								<img class="fire2" src={fire} />
							</div>
						</div>

					</div>
					<Ticker />

					<Story />

					<Ticker2 />

					<Element name="mint">
						<div class="boxWrap2Mint">

							<div class="conT2">MINT AREA</div>

							{this.state._showMintBox === 0 ?
								(
									<div class="mintDiv">
										<video class="rabbitVid2" src={moonBunny} autoPlay loop playsInline muted />
										<div class="mintCon">
											<p></p>
											<div class="price"><div>USD Price $ 333</div></div>
											<div class="price"><div>ETH Price Ξ 0.18</div></div>

											<p></p>
											<div class="mintbuttondiv2">
												<button class="btnfos-0-3">MINTING COMING SOON!</button>
												<p></p>
 											</div>

										</div>

									</div>) :
								(<div>
									{this.state._usdMint > 0 ?
										(<div class="mintDiv">
											<video class="rabbitVid2" src={moonBunny} autoPlay loop playsInline muted />
											<div class="mintCon">
												<div class="totalSupply">{this.state.totalSupply} / 10000</div>

												<div class="price"><div>Mint Price $ 333</div></div>

												<div class="minting_count_button">
													<div class="center">
														<button onClick={this.onSubmitMinus} class="btnfos-0-2">-</button>
													</div>

													<div>
														<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
													</div>

													<div class="center">
														<button onClick={this.onSubmitPlus} class="btnfos-0-2">+</button>
													</div>
												</div>

												{this.state.walletAddress === '' ?

													(<div class="mintbuttondiv">
														<button onClick={this.walletConnect} class="btnfos-0-3">CONNECT</button>
													</div>) :
													(<div class="mintbuttondiv">
														<div class="mintbuttondiv">
															{this.state._approved !== true ?
																(<form onSubmit={this.approve}>
																	<button class="btnfos-0-3" type="submit">
																		Approve</button>
																</form>) :

																(<form onSubmit={this.onSubmit2}>
																	<button class="btnfos-0-3" type="submit">Mint Here</button>
																</form>)}
														</div>
													</div>)}
												<div>

													{this.state.statusError ? (
														<div class="errorMessage">
															<div >Sorry, something went wrong please try again later</div>
														</div>)
														: null}

													{this.state.statusLoading ? (
														<div class="loadingContainer">
															<div>
																<div class="loadingText">Minting your NFT</div>
															</div>
														</div>)
														: null}

													{this.state.success ? (

														<div class="success-message">
															<h2>Successful NFT Minting!</h2>
															<p>Your NFT has been successfully minted.</p>
														</div>)
														: null}

												</div>

											</div>
											<img onClick={this.backMintBox} class="back" src={back} />
										</div>) :

										(<div class="mintDiv">
											<video class="rabbitVid2" src={moonBunny} autoPlay loop playsInline muted />
											<div class="mintCon">
												<div class="totalSupply">{this.state.totalSupply} / 10000</div>

												<div class="price"><div>Mint Price Ξ 0.18</div></div>

												<div class="minting_count_button">
													<div class="center">
														<button onClick={this.onSubmitMinus} class="btnfos-0-2">-</button>
													</div>

													<div>
														<div class="nftminter2"><span class="nftamount">{this.state.nftMintingAmount}</span></div>
													</div>

													<div class="center">
														<button onClick={this.onSubmitPlus} class="btnfos-0-2">+</button>
													</div>
												</div>

												{this.state.walletAddress === '' ?

													(<div class="mintbuttondiv">
														<button onClick={this.walletConnect} class="btnfos-0-3">CONNECT</button>
													</div>) :
													(<div class="mintbuttondiv">
														<div class="mintbuttondiv">
															<form onSubmit={this.ethMint}>
																<button class="btnfos-0-3" type="submit">Mint Here</button>
															</form>
														</div>
													</div>)}
												<div>

													{this.state.statusError ? (
														<div class="errorMessage">
															<div >Sorry, something went wrong please try again later</div>
														</div>)
														: null}

													{this.state.statusLoading ? (
														<div class="loadingContainer">
															<div>
																<div class="loadingText">Minting your NFT</div>
															</div>
														</div>)
														: null}

													{this.state.success ? (

														<div class="success-message">
															<h2>Successful NFT Minting!</h2>
															<p>Your NFT has been successfully minted.</p>
														</div>)
														: null}

												</div>

											</div>

											<img onClick={this.backMintBox} class="back" src={back} />

										</div>)}
								</div>)}
							<img class="mintLine" src={mintbg} />
						</div>
						<Ticker />
					</Element>
					<Carousel />
					<Ticker />

					<Shop />

					<Ticker />

					<Element name="team">
						<Team />
					</Element>

					<Element name="faq">
						<FAQ />
					</Element>

					<Ticker />

					<CustomForm />

					<Ticker3 />


					<Element name="rm">
						<Roadmap />
					</Element>


				</div>


			</div>)
	}
}

export default App;
