import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import team1 from '../assets/t1.png'
import team2 from '../assets/t2.png'
import rocks from '../assets/rocks.png'
import rocks2 from '../assets/rocks2.png'

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Team extends Component {

    render() {
        return (

            <div class="boxWrapTeam">

                    <div class="about2">
                        <div class="conT2">TEAM</div>
                        <div class="teamSection"  data-aos="fade-in" data-aos-duration="1000">
                            <div class="box1">
                                <img src={team1}/>
                                <div class="name">STEPHANIE TINA TENG</div>
                                <div class="name2">FOUNDER</div>
                            </div>

                            <div class="box1">
                                <img src={team2}/>
                                <div class="name">AYMAN HASSANEIN</div>
                                <div class="name2">FOUNDER</div>
                            </div>
  
                        </div>
                    </div>
                <img class="rocks" src={rocks}/>
                <img class="rocks2" src={rocks2}/>
                <img class="rocks3" src={rocks2}/>
                <img class="rocks4" src={rocks2}/>
            </div>
        )
    }
}

export default Team;

