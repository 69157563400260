import React, { useState, useEffect } from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { refresh } from 'aos';

const CustomForm = ({ status, message, onValidated }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (email && firstName && lastName && email.includes('@')) {
            onValidated({
                EMAIL: email,
                MERGE1: firstName,
                MERGE2: lastName,
            });
        }
    };

    const refreshForm = async (event) => {
        event.preventDefault();
        window.location.reload();
    };

    useEffect(() => {
        if (status === 'success') clearFields();
        if (modalOpen && status === 'success') clearFields();
    }, [status, modalOpen]);

    const clearFields = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
    };

    return (
        <div class="boxWrap2Form">
         
            <form class="inputFormMain" onSubmit={handleSubmit}>
                <h2>
                    {status === 'success' ? 'Success!' : 'Join our email list for future updates.'}
                </h2>

                {status === 'sending' && <div class="mc__alert--sending">sending...</div>}
                {status === 'error' && (
                    <div
                        class="mc__alert--error"
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {status === 'success' && (
                    <div
                        class="mc__alert--success"
                        dangerouslySetInnerHTML={{ __html: message }}

                    />
                )}

                {status !== 'success' && (
                    <div class="mc__field-container">
                        <div class="inputMain">
                            <div class="inputH">First Name</div>
                            <input
                                label="First Name"
                                onChange={(e) => setFirstName(e.target.value)}
                                type="text"
                                value={firstName}
                                placeholder="Jane"
                                required
                            />
                        </div>

                        <div class="inputMain">
                            <div class="inputH">Last Name</div>
                            <input
                                label="Last Name"
                                onChange={(e) => setLastName(e.target.value)}
                                type="text"
                                value={lastName}
                                placeholder="Doe"
                                required
                            />
                        </div>

                        <div class="inputMain">
                            <div class="inputH">Email</div>
                            <input
                                label="Email"
                                onChange={(e) => setEmail(e.target.value)}
                                type="email"
                                value={email}
                                placeholder="your@email.com"
                                required
                            />
                        </div>
                    </div>
                )}

                {status === 'success' ? (
                    <button onClick={refreshForm} class="closeBtn">
                        close
                    </button>
                ) : (
                    <button class="subscribe" type="submit" >Submit</button>
                )}
            </form>

        </div>
    );
}


const MailchimpForm = props => {
    const url = `https://diamondbunnyclub.us21.list-manage.com/subscribe/post?u=a9d70caee6f5986313f8ddb0f&id=eb75b8ec1f`;

    return (

        <div class="mc__form-container">
            <MailchimpSubscribe
                url={url}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>

    )
}

export default MailchimpForm;